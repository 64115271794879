import React from "react";
import { AppTitle } from "../../Reuseable/AppTitle/AppTitle";
import ImgLoaded from "../../Reuseable/Imgloaded/Imgloaded";
import { TemplatePage } from "./TemplatePage";

export const Seven = () => {
  return (
    <TemplatePage
      imgs={[
        "https://i.imgur.com/ird02n8.jpg",
        "https://i.imgur.com/Ok3VDdl.jpg",
        "https://i.imgur.com/f8tjAAQ.jpg",
        "https://i.imgur.com/vlVTzXO.jpg"
      ]}
      carouselTitle="2e édition du Festival international du film Canada Chine"
      className="seven"
      galleryKey="seven"
      title="2017"
    >
      {/* <AppTitle text="News" />
      <div className="applayout flexcol themebg">
        <div className="newsarticle">
          <ImgLoaded />
          <div className="articlecontent">
            <h2>News 1</h2>
            <span className="graytext">October 19, 2022</span>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
              repellat quasi minus eveniet architecto distinctio dignissimos
              impedit saepe autem sed.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
              repellendus quia saepe numquam veritatis officia. Doloribus
              maxime, sint magnam assumenda enim, voluptas ipsa veniam dicta
              labore, sapiente beatae quaerat porro.
            </p>
          </div>
        </div> */}
      {/* <div className="newsarticle">
          <ImgLoaded />
          <div className="articlecontent">
            <h2>News 2</h2>
            <span className="graytext">October 19, 2022</span>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
              repellat quasi minus eveniet architecto distinctio dignissimos
              impedit saepe autem sed.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
              repellendus quia saepe numquam veritatis officia. Doloribus
              maxime, sint magnam assumenda enim, voluptas ipsa veniam dicta
              labore, sapiente beatae quaerat porro.
            </p>
          </div>
        </div>
      </div> */}
      {/* <AppTitle text="Video" />
      <div className="applayout videogrid">
        <div className="videoitem">
          <iframe
            src={"https://www.youtube.com/embed/ScMzIvxBSi4"}
            frameborder="0"
            title={"video.src"}
          ></iframe>
        </div>
      </div> */}
      {/* <AppTitle text="Paragraphs/Description" />
      <div className="paragraphs applayout themebg">
        <h2>Title 1</h2>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum qui
          aliquid mollitia at quae rerum praesentium doloribus quasi ratione,
          cupiditate molestiae tenetur temporibus aliquam ad quibusdam ea
          excepturi animi soluta illo eum reiciendis debitis sit unde autem!
          Labore, voluptate repudiandae!
        </p>
        <h2>Title 2</h2>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum qui
          aliquid mollitia at quae rerum praesentium doloribus quasi ratione,
          cupiditate molestiae tenetur temporibus aliquam ad quibusdam ea
          excepturi animi soluta illo eum reiciendis debitis sit unde autem!
          Labore, voluptate repudiandae!
        </p>
        <h2>Title 3</h2>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum qui
          aliquid mollitia at quae rerum praesentium doloribus quasi ratione,
          cupiditate molestiae tenetur temporibus aliquam ad quibusdam ea
          excepturi animi soluta illo eum reiciendis debitis sit unde autem!
          Labore, voluptate repudiandae!
        </p>
      </div> */}
      {/* <div className="iconsrows">
        <div className="iconbox">
          <i className="fal fa-cubes"></i>
          <h2>Icon 1</h2>
          <span>Lorem ipsum dolor sit amet.</span>
        </div>
        <div className="iconbox">
          <i className="fal fa-cubes"></i>
          <h2>Icon 1</h2>
          <span>Lorem ipsum dolor sit amet.</span>
        </div>
        <div className="iconbox">
          <i className="fal fa-cubes"></i>
          <h2>Icon 1</h2>
          <span>Lorem ipsum dolor sit amet.</span>
        </div>
      </div> */}
    </TemplatePage>
  );
};
