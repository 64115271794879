import React from "react";
import { TemplatePage } from "./TemplatePage";

export const Six = () => {
  return (
    <TemplatePage
      imgs={[
        "https://i.imgur.com/K0lJKxz.jpg",
        "https://i.imgur.com/iPrDMrY.jpg",
        "https://i.imgur.com/zzrrRko.jpg"
      ]}
      carouselTitle="1e édition du Festival international du film Canada Chine"
      className="six"
      galleryKey="six"
      title="2016"
    ></TemplatePage>
  );
};
