import React from "react";
import {  imgs } from "../../../Arrays";
import { TemplatePage } from "./TemplatePage";

export const Eight = (props) => {
 

  return (
    <TemplatePage
      className="eight"
      carouselTitle="3e édition du Festival international du film Canada Chine"
      imgs={imgs}
      galleryKey='eight'
      title='2018'
    >

      <div className="fixedimgcont">
        <div className="pcontent">
          <h1>Communiqué de Presse du Gala de Clôture</h1>
          <p>
            Le 3e Festival international du film Canada Chine s'est conclu avec
            succès par son gala et sa cérémonie de remise de prix le 3e Festival
            international du film Chine-Canada a tenu son gala de clôture et sa
            cérémonie de remise des prix au Windsor à Montréal, le 27 septembre
            2018.
          </p>
        </div>
        <div className="imgparallax"></div>
      </div>
    </TemplatePage>
  );
};
