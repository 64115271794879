import "./styles.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import ContextAppProvider from "./ContextAPI";
import React, { useState } from "react";
import Body from "./Components/Body/Body";
import { Navbar } from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { ScrollToTop } from "./ScrollToTop";

export default function App() {
  return (
    <Router>
      <ContextAppProvider>
        <ScrollToTop />
        <Navbar />
        <Body />
        <Footer />
      </ContextAppProvider>
    </Router>
  );
}
