// export const news = [
//   {
//     title: 'CCIFF 2018 Closing Gala Press Release',
//     date: 'OCTOBER 2, 2018',
//     text: 'The 3rd Canada China International Film Festival was successfully concluded with its Gala and Awards Ceremony',
//     subText: 'At 5:30 pm on September 27, 2018, the 3rd China-Canada International Film Festival held its Closing Gala & Awards Ceremony at the Le Windsor in Montreal.',
//     img: require('./assets/2018/news/2018_article_1.jpg')
//   },
//   {
//     title: 'Le film « Innocent » sera présenté en ouverture du CCIFF',
//     date: 'September 22, 2018',
//     text: 'Opening Screening Ceremony and Cocktail Reception.',
//     subText: 'At 5:30 pm on September 27, 2018, the 3rd China-Canada International Film Festival held its Closing Gala & Awards Ceremony at the Le Windsor in Montreal.',
//     img: require('./assets/2018/news/2018_article_2.jpg')
//   },
//   {
//     title: 'Third Edition of Canada China International Film Festival Shanghai Press Conference',
//     date: 'JULY 4, 2018',
//     text: ' Around 60 guests of important government representatives attended.',
//     subText: 'On June 20th 2018, the third edition of Canada China International Film Festival (CCIFF) Shanghai press conference was held at Fairmont Peace Hotel in Shanghai',
//     img: require('./assets/2018/news/2018_article_3.jpg')
//   },
// ]
export const imgs = [    
  'https://i.imgur.com/7sv8X14.jpg',
  'https://i.imgur.com/ird02n8.jpg',
  'https://i.imgur.com/eor9tku.jpg',
  'https://i.imgur.com/y6UxGVo.jpg',
  'https://i.imgur.com/hrWUzs9.jpg',
]
export const playlistarray = {
  eight: {
    video: 'KAxEnxXLRzA',
    subvideos: [
      '5euq-fq_Bbo',
      'k27FmdSo9Jo',
      'Nxi0_HQXRXY',
    ],
    playlist: 'PLPdx1tagkAyyqUwH7H8Pzj63HmpdW10lx'
  },
  seven: {
    video: 'B5byE4NA60s',
    subvideos: [
      '7RKEtnXbmZM',
      'akoOPeo1Hbc',
      'yX0mltx0RmA'
    ],
    playlist: 'PLPdx1tagkAyyY4YhImLZ4WFeXyVWLboBE'
  },
  six: {
    video: 'vXtZlxhi4pk',
    subvideos: [
      'm5hg3qE47qY',
      'zs4mWoaW7js',
      'Hp-A46p-Tl'
    ],
    playlist: 'PLPdx1tagkAywW2L56Lk66yNk7BENNZN3s'
  }
}
export const galleryarray = {
  // {
  //   img: 'https://live.staticflickr.com/4302/35208993994_3d1160d792_k.jpg',
  //   title: 'Image Gallery',
  //   isFirst: true
  // },
  twenty: [
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157695784098730',
      img: 'https://live.staticflickr.com/1858/29918080067_71fa056e4f_k.jpg',
      title: '9-22-2018',
      photoCount: 41
    },
  ],
  eight: [
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157695784098730',
      img: 'https://live.staticflickr.com/1858/29918080067_71fa056e4f_k.jpg',
      title: '9-22-2018',
      photoCount: 41
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157693962370624',
      img: 'https://live.staticflickr.com/4780/40886215862_f8cfa871ee_k.jpg',
      title: 'Conférence de presse Montréal',
      photoCount: 80
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157698497297695',
      img: 'https://live.staticflickr.com/1806/42177441945_42540f60a6_h.jpg',
      title: '3e édition-Conférence de presse de Shanghai',
      photoCount: 10
    },
    {
      link: 'https://www.facebook.com/media/set/?set=a.2005690556399516&type=3',
      img: 'https://i.imgur.com/HkaknCJ.jpg',
      title: 'Gala Award',
      photoCount: 94
    },
    {
      link: 'https://www.facebook.com/media/set/?set=a.2007415359560369&type=3',
      img: 'https://i.imgur.com/J6OOG0J.jpg',
      title: 'Sommet et exposition ET',
      photoCount: 26
    },
    {
      link: 'https://www.facebook.com/media/set/?set=a.2003399429961962&type=3',
      img: 'https://i.imgur.com/N8EiqzM.jpg',
      title: '09-23-2018',
      photoCount: 50
    },
    {
      link: 'https://www.facebook.com/media/set/?set=a.2004892266479345&type=3',
      img: 'https://i.imgur.com/bk3GTEv.jpg',
      title: '9-25-2018',
      photoCount: 9
    },
  ],
  seven: [
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157683866473793',
      img: 'https://live.staticflickr.com/4302/35208993994_3d1160d792_k.jpg',
      title: 'Shanghai',
      photoCount: 5
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157686516782405',
      img: 'https://live.staticflickr.com/4294/35238476843_0811b4d4f7_k.jpg',
      title: 'Beijing',
      photoCount: 12
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157678471645503',
      img: 'https://live.staticflickr.com/3759/32819640833_ef9a0ca3ae_k.jpg',
      title: 'Conférence de presse Montréal',
      photoCount: 127
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157683866473793',
      img: 'https://live.staticflickr.com/4470/37012167740_65d5a9dadf_k.jpg',
      title: 'ET',
      photoCount: 4
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157689486044235',
      img: 'https://live.staticflickr.com/4512/36601005343_4ec82c63d2_k.jpg',
      title: 'Projection de films ',
      photoCount: 39
    },
  ],
  six: [
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157674330600040',
      img: 'https://live.staticflickr.com/5664/30107851984_9e3116e232_k.jpg',
      title: 'Exposition de films',
      photoCount: 34
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157675415153256',
      img: 'https://live.staticflickr.com/5683/29842426093_4bfe628957_k.jpg',
      title: 'Cours de maître',
      photoCount: 1
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157674330569430',
      img: 'https://live.staticflickr.com/5592/29864391983_673128796a_k.jpg',
      title: 'Sommet et exposition sur les technologies du divertissement',
      photoCount: 111
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157674283542770',
      img: 'https://live.staticflickr.com/5465/29822378093_e9fd657445_k.jpg',
      title: 'Images de la cérémonie d\'ouverture',
      photoCount: 262 
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157671940762483',
      img: 'https://live.staticflickr.com/5720/30456460915_12049a59ab_k.jpg',
      title: 'Images de la cérémonie de clôture',
      photoCount: 170
    },
    {
      link: 'https://www.flickr.com/photos/cciff/albums/72157674330579890',
      img: 'https://live.staticflickr.com/5488/30650865835_8151201194_k.jpg',
      title: 'Voix de femme',
      photoCount: 20
    },
  ]





 




}
export const videos = [
  {
    src: 'https://www.youtube.com/embed/vSEixkH8AMs'
  },
  // {
  //   src: 'https://www.youtube.com/embed/N6tbVG97KAQ'
  // },
]