import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import ImgLoaded from "../Reuseable/Imgloaded/Imgloaded";
import "./Body.css";
import { Six } from "./Pages/2016";
import { Seven } from "./Pages/2017";
import { Eight } from "./Pages/2018";

const Body = () => {
  const archiveLinks = [
    {
      year: "2023",
      img: "https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/11f461f8-a6a9-a3de-d9bc-9b3205ff05de.jpg",
      href: "https://cciff.ca/Archive/2023"
    },
    {
      year: "2022",
      img: "https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/01a96573-b5f7-e6d1-d7fc-c2b3296efe9e.png",
      href: "https://cciff.ca/Archive/2022"
    },
    {
      year: "2021",
      img: "https://cciff.ca/Content/2021/img/En/MontrealOpening/montreal_opening.jpg",
      href: "https://cciff.ca/Archive/2021"
    },
    {
      year: "2020",
      img: "https://i.imgur.com/VoXIiOw.jpg",
      href: "https://cciff.ca/Archive/2020"
    },
    {
      year: "2019",
      img: "https://i.imgur.com/QW2zlBW.jpg",
      href: "https://cciff.ca/Archive/2019"
    },
    {
      year: "2018",
      img: "https://i.imgur.com/7sv8X14.jpg",
      link: "/2018"
    },
    {
      year: "2017",
      img: "https://i.imgur.com/ird02n8.jpg",
      link: "/2017"
    },
    {
      year: "2016",
      img: "https://i.imgur.com/K0lJKxz.jpg",
      link: "/2016"
    }
  ];
  const archiveLinksrow = archiveLinks.map((archive) => {
    let Tag = archive.link ? Link : "a";
    return (
      <div className="archivebox">
        <Tag exact to={archive.link} href={archive.href}>
          <ImgLoaded img={archive.img} />
        </Tag>
        <Tag
          exact
          to={archive.link}
          href={archive.href}
          className="archivelink"
        >
          {archive.year}
        </Tag>
      </div>
    );
  });

  return (
    <div className="body">
      <Switch>
        <Route exact path="/">
          <div className="indexpage">
            <div className="archivesrow">{archiveLinksrow}</div>
          </div>
        </Route>
        <Route path="/2016">
          <Six />
        </Route>
        <Route path="/2017">
          <Seven />
        </Route>
        <Route path="/2018">
          <Eight />
        </Route>
      </Switch>
    </div>
  );
};
export default Body;
