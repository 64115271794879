import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import ImgLoaded from "../Reuseable/Imgloaded/Imgloaded";
import "./Footer.css";

const Footer = () => {
  const Footertitle = ({ title }) => {
    return (
      <div className="footertitle">
        <strong>{title}</strong>
        <div className="bbg">
          <div className="bbr"></div>
        </div>
      </div>
    );
  };
  const links = [
    {
      text: "Partenaires",
      link: "https://cciff.ca/sponsors.html"
    },
    {
      text: "Rapport Annuel",
      link: "https://sites.google.com/cciff.net/annual-report/home"
    },
    {
      text: "Galerie Photo",
      link: "https://www.flickr.com/photos/cciff/"
    },
    {
      text: "S'inscrire",
      link: "https://mailchi.mp/b471774ca927/cciff-newsletter"
    },
    {
      text: "Archive",
      linkR: "/"
    }
  ];
  const linksrow = links.map((link) => {
    let El = link.linkR ? Link : "a";
    return (
      <El href={link.link} to={link.linkR}>
        <i className="fal fa-chevron-right"></i>
        <span>{link.text}</span>
      </El>
    );
  });
  const sociallinks = [
    {
      icon: "fab fa-facebook-f",
      link: "https://www.facebook.com/cciff.ca/"
    },
    {
      icon: "fab fa-twitter",
      link: "https://twitter.com/cciff_ca"
    },
    {
      icon: "fab fa-instagram",
      link: "https://www.instagram.com/cciff_ca/"
    },
    {
      icon: "fab fa-weibo",
      link: "https://www.weibo.com/5878518557/profile?topnav=1&wvr=6&is_all=1"
    },
    {
      icon: "fab fa-youtube",
      link: "https://www.youtube.com/channel/UCcJ-jSP6Cya8o-aWoB4l3Pw"
    },
    {
      icon: "fab fa-linkedin",
      link: "https://www.linkedin.com/company/cciff/"
    },
    {
      icon: "fab fa-flickr",
      link: "https://www.flickr.com/photos/148397603@N02/"
    }
  ];
  const sociallinksrow = sociallinks.map((icon) => {
    return (
      <a href={icon.link} target="__blank">
        <i className={`socialicon ${icon.icon}`}></i>
      </a>
    );
  });

  return (
    <div className="footer flexrow">
      <div className="contentsection flexcol">
        <div className="logotext flex">
          <span className="border vertical"></span>
          <strong>CCIFF</strong>
        </div>
        <p>
          Le Festival International du Film Canada-Chine est organisé par Canada
          China Art-Tech, un organisme à but non lucratif, qui a pour la mission
          de renforcer la relation et la connaissance entre le Canada et la
          Chine.
        </p>
        <ImgLoaded
          alt="cciff qr code"
          className="qrcode"
          img={
            "https://i.imgur.com/SbMCN25.png"
          }
        />
      </div>
      <div className="linkssection flexcol">
        <Footertitle title="Liens Rapides" />
        <div className="linksrow">{linksrow}</div>
      </div>
      <div className="office flexcol">
        <Footertitle title="Notre Bureau" />
        <span>
          <a
            target="__blank"
            href="https://www.google.com/maps/place/Faubourg+Tower,+Montreal,+QC/@45.4946158,-73.5797785,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc91a6bd0e5801d:0x9bd3130820f88651!8m2!3d45.4946158!4d-73.5775898"
          >
            1250 Guy St
          </a>
        </span>
        <span>Montréal, QC</span>
        <span>Canada</span>
        <span>
          Téléphoner: <a href="tel:514-416-6778">(514) 416-6778</a>
        </span>
        <span>
          Email: <a href="mailto:info@cciff.net">info@cciff.net</a>
        </span>
        <div className="sociallinks">{sociallinksrow}</div>
      </div>
    </div>
  );
};
export default Footer;
