import React, { createContext, useEffect, useRef, useState } from 'react'


export const ContextApp = createContext()
    const ContextAppProvider = props => {



  return <ContextApp.Provider 
           value={{}}>
      {props.children}
  </ContextApp.Provider>
}
export default ContextAppProvider