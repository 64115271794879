import React from 'react'
import ImgLoaded from '../Imgloaded/Imgloaded'
import './Logo.css'

export const Logo = () => {
  
  return (
    <a className="logo" href='https://cciff.ca/'>
      <ImgLoaded img={'https://i.imgur.com/iYjmE5u.png'} />
    </a>
  )
}