import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Logo } from '../Reuseable/Logo/Logo'
import './Navbar.css'

export const Navbar = () => {
  const [scrolled, setScrolled] = useState(false)
  const location = useHistory()
  function handleScroll(){
    if(window.scrollY > 50) {
      setScrolled(true)
    }
    else {
      setScrolled(false)
    }
  }
  const links = [
    {
      text: 'À PROPOS',
      href: 'https://cciff.ca/about.html'
    },
    {
      text: 'SOUMISSION',
      href: 'https://cciff.ca/submission.html'
    },
    {
      text: 'PROJECTION DE FILMS',
      href: 'https://cciff.ca/screening.html'
    },
    {
      text: 'NOUS JOINDRE',
      href: 'https://cciff.ca/join_us.html'
    },
    {
      text: 'SOUTIEN',
      href: 'https://cciff.ca/sponsors.html'
    },
    {
      text: 'PRESSE',
      href: 'https://cciff.ca/coming_soon.html'
    },
  ]
  const linksrow = links.map(link=> {
    return (
      <a href={link.href}>{link.text}</a>
    )
  })
  useEffect(()=>{
      window.addEventListener('scroll', handleScroll)
  },[location])

  return (
    <>
    <div className={`navbar scrollednav`}>
      <Logo />
      {/* <div className="navlinksrow flexrow">
        {linksrow}
      </div> */}
    </div>
    <i className={`scrolltotop fa fa-chevron-up ${scrolled? 'visiblebtn':''}`} onClick={()=> window.scrollTo({top: 0})}></i>
    </>
  )
}