import React from 'react'
import { Helmet } from 'react-helmet'
import { galleryarray, playlistarray } from '../../../Arrays'
import { AppTitle } from '../../Reuseable/AppTitle/AppTitle'
import { AppCarousel } from '../../Reuseable/Carousel/Carousel'
import ImgLoaded from '../../Reuseable/Imgloaded/Imgloaded'

export const TemplatePage = (props) => {
  const {className, imgs, carouselTitle, galleryKey, title} = props
  const galleryarrayrow = galleryarray[galleryKey].map(img=> {
    if(!img.isFirst) {
      return(
        <a target='__blank' href={img.link} className="galleryitem" key={img.link}>
          <div className="pcontent">
            <h1>{img.title}</h1>
            <p>{img.photoCount} {img.photoCount === 1 ? 'photo' : 'photos'}</p>
          </div>
          <ImgLoaded img={img.img}/>
       </a>
      )
    }
    else {
      return (
        <div className="galleryfirst galleryitem">
          <h1>{img.title}</h1>
          <ImgLoaded img={img.img}/>
        </div>
      )
    }
  })
  let info = [
    {
      img: 'https://i.imgur.com/Gkl8coR.png',
      isHorizontal: true
    },
    {
      img: 'https://i.imgur.com/eShkiJH.png',
      isHorizontal: true
    },
    {
      img: 'https://i.imgur.com/tHtr2dO.png',
    },
    {
      img: 'https://i.imgur.com/olX8ysV.png',
      isHorizontal: true
    },
    {
      img: 'https://i.imgur.com/RJ2OL02.png',
      isHorizontal: true
    },
    {
      img: 'https://i.imgur.com/My1nNpE.png',
      isHorizontal: true
    },
    {
      img: 'https://i.imgur.com/D4kUDb9.png',
      isHorizontal: true
    }
  ];

 
  return (
    <div className={className}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppCarousel imgs={imgs} title={carouselTitle}/>
      <div className="content">

        <h1 className="applayout imggallerytitle pagetitle">Playlists</h1>
        <div className="applayout videogrid">
          <div className="playlist flexrow">
            <div className="mainvid">
              <iframe src={`https://www.youtube.com/embed/${playlistarray[galleryKey].video}`} frameborder="0"></iframe>
            </div>
            <div className="subplaylistvid flexcol">
              {
                playlistarray[galleryKey].subvideos.map(src=> {
                  return (
                    <div className="subvideo">
                      <iframe src={`https://www.youtube.com/embed/${src}`} frameborder="0"></iframe>
                    </div>
                  )
                })
              }
              <a className="gotoplaylist" target='__blank' href={`https://www.youtube.com/playlist?list=${playlistarray[galleryKey].playlist}`}>
                <h2>Aller à la liste de lecture</h2>
              </a>
            </div>
          </div>
        </div>
        <AppTitle text={'Galerie d\'images'} />
        <div className="imggallery">
         {galleryarrayrow}
        </div>
        {props.children}

        <div className="infoslider">
          <AppTitle text='Nos Partenaires' />
          <AppCarousel info={info} slidesToShow={5}/>
          <div className="paddingbottom"></div>
        </div> 
      </div>
    </div>
  )
}